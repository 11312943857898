import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RpConsumersComponent } from './rp-consumers/rp-consumers.component';
import { RpCreateConsumersComponent } from './rp-create-consumers/rp-create-consumers.component';

const routes: Routes = [

  { path: 'consumers', component: RpConsumersComponent },
  { path: 'consumers/createConsumer', component: RpCreateConsumersComponent },
  // { path: 'consumers/wallet', component: CosumerWalletComponent },


];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ConsumersRoutingModule { }
