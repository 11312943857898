import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import {
  FilterableSettings,
  DataBindingDirective,
  GridDataResult,
  PageChangeEvent,
  DataStateChangeEvent,
  GridComponent,
} from '@progress/kendo-angular-grid';
import { PDFExportComponent } from '@progress/kendo-angular-pdf-export';

import { Workbook } from '@progress/kendo-angular-excel-export';

import { SVGIcon, filePdfIcon, fileExcelIcon } from '@progress/kendo-svg-icons';
import Swal from 'sweetalert2';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Store, select } from '@ngrx/store';
import { Router } from '@angular/router';
import { process, State } from '@progress/kendo-data-query';
import {
  deleteConsumerResponse,
  getConsumersExportResponse,
  getConsumersResponse,
} from '../../../store/customer/consumer-selector';
import { LoaderService } from '../../../core/services/loader.service';
import { SharedService } from '../../../shared/shared.service';
import {
  orderBy,
  SortDescriptor,
  filterBy,
  CompositeFilterDescriptor,
  FilterDescriptor,
} from '@progress/kendo-data-query';
import {
  deleteConsumer,
  getConsumer,
  getConsumerExport,
  getConsumerStstusId,
  resetState,
} from '../../../store/customer/consumer.action';
import {
  SelectableSettings,
  SelectableMode,
} from '@progress/kendo-angular-grid';
import { RpBulkUpoadComponent } from '../../../shared/components/rp-bulkupload/rp-bulkupload.component';
import {
  Templates,
  table_pageSize,
} from '../../../shared/constants/templatesNames';
import { Subscription } from 'rxjs';

import { ExcelExportComponent } from '@progress/kendo-angular-excel-export';
import { PdfexportService } from '../../../core/services/pdfexport.service';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { SweetAlertIcon } from '../../../shared/constants/enum';
@Component({
  selector: 'app-rp-consumers',
  templateUrl: './rp-consumers.component.html',
  styleUrl: './rp-consumers.component.scss',


})
export class RpConsumersComponent {
  public selectableSettings!: SelectableSettings;
  public checkboxOnly = true;
  public drag = false;
  public mode: SelectableMode = 'multiple';
  public filterMode: FilterableSettings = 'row';
  public filePdfIcon: SVGIcon = filePdfIcon;
  public fileExcelIcon: SVGIcon = fileExcelIcon;

  @ViewChild(DataBindingDirective) dataBinding!: DataBindingDirective;
  breadCrumbItems: Array<{}> | undefined;
  public consumersState: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };
  projectId: any;
  currentUser: any;
  consumerList: any[] = [];

  public pageSize = 10;
  public skip = 0;

  totalUserCount: number = 0;
  currentPage: number = 1;
  public consmergridData: GridDataResult = { data: [], total: 0 };

  public buttonCount = 5;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  // public pageSizes = true;
  public pageSizes = table_pageSize.pageSizes;
  public previousNext = true;
  isexcel: boolean = false;
  isPdf: boolean = false;
  public gridData: unknown[] = [];

  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective | undefined;
  // file upload

  uploadSaveUrl = 'saveUrl'; // should represent an actual API endpoint
  uploadRemoveUrl = 'removeUrl'; // should represent an actual API endpoint
  orderByQuery: string = '';
  queryString: string = '';
  searchedValue: any = '';
  @ViewChild('excelexport', { static: true })
  excelExport!: ExcelExportComponent;
  @ViewChild('pdfGrid') public hiddenGrid: GridComponent | undefined;
  consumerRequest = Object.assign({});

  // @ViewChild('excelexport') public hiddenGrid: GridComponent | undefined;
  //@ViewChild('pdfExport', { static: true }) pdfExport: PDFExportComponent | undefined;
  constructor(
    private modalService: BsModalService,
    private store: Store,
    private router: Router,
    private loaderService: LoaderService,
    private sharedService: SharedService,
    private PdfexportService: PdfexportService
  ) {
    if (history.state.consumerRequest != undefined && history.state.consumerRequest !== null) {
      this.consumerRequest = history.state.consumerRequest;

    }
    // console.log(this.consumerRequest)
    this.currentUser = JSON.parse(localStorage.getItem('currentUser') ?? '');
    this.isFilter = localStorage.getItem('filter');

    this.projectId = this.currentUser?.project.id;
    this.getConsumerStoreInfo();
    this.breadCrumbItems = [{ label: 'Consumers' }];
  }
  public sort: SortDescriptor[] = [];
  public filter: CompositeFilterDescriptor = { logic: 'and', filters: [] };
  modalRef!: BsModalRef;
  config: any = {
    backdrop: true,
    ignoreBackdropClick: true,
  };


  public onValueChange(ev: string): void {
    console.log(ev)
    console.log(ev.length)
    console.log(this.searchedValue)

    if (ev.length == 0) {
      this.consumerPageChange({ skip: 0, take: this.pageSize })
      this.store.dispatch(
        getConsumer({
          pageIndex: this.currentPage,
          pageSize: this.pageSize,
          searchTerm: this.searchedValue,
          filter: this.queryString,
          orderBy: this.orderByQuery,
          ProjectId: this.projectId,
        })
      );
    }
  }

  public onFilter(value: any): void {
    this.consumerPageChange({ skip: 0, take: this.pageSize })
    /* this.store.dispatch(
      getConsumer({
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        searchTerm: this.searchedValue,
        filter: this.queryString,
        orderBy: this.orderByQuery,
        ProjectId: this.projectId,
      })
    ); */
  }

  deleteConsumer(dataItem: any): void {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-primary ms-2',
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, cancel!',
        showCancelButton: true,
      })
      .then((result) => {
        if (result.value) {
          this.store.dispatch(
            deleteConsumer({ id: dataItem.id, projectId: this.projectId })
          );
          /*  swalWithBootstrapButtons.fire(
             'Deleted!',
             'Your file has been deleted.',
             'success'
           ); */
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            'Cancelled',
            'Your imaginary file is safe :)',
            'error'
          );
        }
      });
  }

  onSortChange(sort: SortDescriptor[]): void {
    this.consumersState.sort = sort;
    console.log(this.consumersState.sort);
    const orderByQuery = this.sharedService.createOrderByQuery(
      this.consumersState.sort
    );

    this.orderByQuery = orderByQuery;

    this.store.dispatch(
      getConsumer({
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        searchTerm: this.searchedValue,
        filter: this.queryString,
        orderBy: this.orderByQuery,
        ProjectId: this.projectId,
      })
    );
  }

  public onprojectFilterChange(filter: CompositeFilterDescriptor): void {
    console.log(filter)
    this.consumersState.filter = filter;
    this.skip = 0;
    let isFilter = filter.filters.length > 0 ? true : false;
    localStorage.setItem('filter', JSON.stringify(isFilter));
    this.sharedService.setFilter(filter);
    const queryString = this.sharedService.createQuery(this.consumersState);
    this.queryString = this.sharedService.createQuery(this.consumersState);
    this.store.dispatch(
      getConsumer({
        pageIndex: 1,
        pageSize: this.pageSize,
        searchTerm: this.searchedValue,
        filter: this.queryString,
        orderBy: this.orderByQuery,
        ProjectId: this.projectId,
      })
    );
    console.log(queryString);
  }

  /**
   * Open center modal
   * @param bulkUploadModal
   */
  bulkUploadModal(bulkUploadModal: any) {
    this.modalRef = this.modalService.show(bulkUploadModal);
  }

  openBulkUpload() {
    let pageData = { title: Templates.CONSUMER.id, projectId: this.projectId };
    this.modalRef = this.modalService.show(RpBulkUpoadComponent, {
      initialState: { pageData },
      ...this.config,
    });
    // this.modalRef = this.modalService.show(RpUserProfileComponent, { initialState: { profileData }, class: 'modal-lg' });

    this.modalRef.content.output.subscribe((response: any) => {
      console.log('Profile data received in header:', response);
      if (response == 'renderNewData') {
        this.getConsumerData();
      }
      // Handle the received profile data here
    });
  }
  isFilter: any
  ngOnInit(): void {
    this.selectableSettings = {
      checkboxOnly: this.checkboxOnly,
      mode: this.mode,
      drag: this.drag,
    };
    console.log(this.consumerRequest);
    console.log(Object.keys(this.consumerRequest).length > 0);

    if (this.consumerRequest && typeof this.consumerRequest === 'object' && Object.keys(this.consumerRequest).length > 0) {
      let status = this.consumerRequest.consumerStatus
      this.consumersState = {
        skip: 0,
        take: 10,
        filter: {
          logic: 'and',
          filters: [
            {
              field: 'isActive',
              operator: 'eq',
              value: status,
            },
          ],
        },
      };

      this.queryString = `$filter=isActive eq ${status}`;
    }

    if (this.isFilter == 'true') {
      //this.skip = 5;
      //this.skip = 1 * this.pageSize;
      // this.currentPage = localStorage.getItem('currentPage') ? Number(localStorage.getItem('currentPage')) : 1;
      // let skip = localStorage.getItem('currentSkip') ? Number(localStorage.getItem('currentSkip')) : 10;
      // this.skip = skip * this.currentPage;
      this.filter = this.sharedService.getFilter();
      this.consumersState.filter = this.filter;
      this.queryString = this.sharedService.createQuery(this.consumersState);
    }

    this.getConsumerData();
  }
  exportData: any[] = [];
  private exportSubscription: Subscription = new Subscription();
  getConsumerStoreInfo() {
    this.store.dispatch(resetState());
    let getConsumers$ = this.store.pipe(select(getConsumersResponse));
    let deletere$ = this.store.pipe(select(deleteConsumerResponse));

    this.exportSubscription = this.store
      .pipe(select(getConsumersExportResponse))
      .subscribe((res: any) => {
        if (res) {
          if (res.items.length > 0) {
            console.log(this.selectedRowdata);
            this.loaderService.setLoading(false);
            const transformedData = [...res.items].map((item: any) => ({
              'First Name': item.firstName,
              'Last Name': item.lastName,
              'Mobile Number': item.phoneNumber,
              'status': item.isActive == true ? 'Active' : 'Inactive',
              'Email': item.primary_Email,
              'Residential Unit': item.residentialUnit,
            }));

            this.PdfexportService.downloadExcel(transformedData, 'consumer');
          } else {
            this.sharedService.showMessageDialog(
              'No data to export',
              '',
              SweetAlertIcon.ERROR
            );
          }
        }
      });

    getConsumers$.subscribe((res: any) => {
      this.loaderService.setLoading(false);
      if (res) {
        this.consumerList = [...res.items];
        this.pageSize = res.pageSize;
        this.totalUserCount = res.totalCount;
        this.loadGridTable();
      }

      console.log('consumerList', res, this.consumerList);
    });

    deletere$.subscribe((res: any) => {
      this.loaderService.setLoading(false);
      if (res) {
        this.currentUser = JSON.parse(
          localStorage.getItem('currentUser') ?? ''
        );
        this.projectId = this.currentUser?.project.id;
        this.getConsumerData();
      }

      console.log('consumerList', res, this.consumerList);
    });
  }

  ngOnDestroy() {
    this.exportSubscription.unsubscribe(); // Or set it to [] if you prefer an empty array
  }
  loadGridTable() {
    const state: any = {
      take: this.pageSize,
      sort: this.sort,
      filter: this.filter,
    };
    const processedData = process(this.consumerList, state);
    this.consmergridData = {
      data: this.consumerList,
      total: this.totalUserCount,
    };
    this.loaderService.setLoading(false);
  }
  getConsumerData() {
    this.store.dispatch(
      getConsumer({
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        searchTerm: this.searchedValue,
        filter: this.queryString,
        orderBy: this.orderByQuery,
        ProjectId: this.projectId,
        criteria: '',
      })
    );
  }

  public showTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    // Check if the element is a table cell
    if (element.nodeName === 'TD') {
      const cellValue = element.innerText.trim();

      // Only show the tooltip if the cell is not empty, null, or ''
      if (cellValue && !element.closest('.no-tooltip')) {
        this.tooltipDir?.toggle(element);
      } else {
        this.tooltipDir?.hide();
      }
    } else if (element.classList.contains('k-column-title')) {
      this.tooltipDir?.toggle(element);
    } else {
      this.tooltipDir?.hide();
    }
  }

  /*  exportExcel() {
     this.isPdf = false;
     this.isexcel = true;
     this.store.dispatch(getConsumerExport({ ProjectId: this.projectId, criteria: 'All', }));
 
   }
   public onExport() {
     this.isPdf = true;
     this.isexcel = false;
     this.store.dispatch(getConsumerExport({ ProjectId: this.projectId, criteria: 'All', }));
   } */

  exportAll() {
    this.store.dispatch(
      getConsumerExport({ ProjectId: this.projectId, criteria: 'All' })
    );
  }
  public onExport(type: string) {
    if (type === 'Excel') {
      setTimeout(() => {
        this.hiddenGrid?.saveAsExcel();
      }, 1000);
    } else if (type === 'Pdf') {
      setTimeout(() => {
        this.hiddenGrid?.saveAsPDF();
      }, 1000);
    }
    /*  if (this.selectedRowdata.length == 0) {
       this.store.dispatch(
         getConsumerExport({ ProjectId: this.projectId, criteria: 'All' })
       );
     } else {
       if (type === 'Excel') {
         setTimeout(() => {
           this.hiddenGrid?.saveAsExcel();
         }, 1000);
       } else if (type === 'Pdf') {
         setTimeout(() => {
           this.hiddenGrid?.saveAsPDF();
         }, 1000);
       }
     } */
  }
  protected consumerPageChange({ skip, take }: PageChangeEvent): void {
    this.selectedRowdata = [];
    console.log('>>>', skip, take);
    this.skip = skip;
    this.pageSize = take;
    this.currentPage = Math.floor(skip / take) + 1;
    console.log('currentpage', Math.floor(skip / take) + 1);
    localStorage.setItem('currentSkip', JSON.stringify(this.skip));
    localStorage.setItem('currentPage', JSON.stringify(this.currentPage));
    console.log('currentpage', Math.floor(skip / take) + 1);
    this.getConsumerData();
    this.loadGridTable();
  }
  protected consumerStateChange(state: DataStateChangeEvent): void {
    this.sort = state.sort || [];
    this.pageSize = state.take;
    this.filter = state.filter || { logic: 'and', filters: [] };
    //this.loadGridTable();
  }

  isSelectAll: boolean = true;
  selectedRowdata: any = [];
  deselectedRowdata: any = [];
  onSelect(event: any) {
    console.log(event.selectedRows);
    var selectedval = event.selectedRows;
    var deselectedVal = event.deselectedRows;
    if (selectedval.length > 0) {
      selectedval.forEach((element: any) => {
        this.selectedRowdata.push(element.dataItem);
        console.log(' selected Values : ', this.selectedRowdata);
      });
    }
    if (deselectedVal.length > 0) {
      deselectedVal.forEach((element: any) => {
        this.deselectedRowdata.push(element.dataItem);
        console.log('deselectedRowdata Values : ', this.deselectedRowdata);
      });

      if (this.deselectedRowdata.length > 0) {
        const idsToRemove = new Set(
          this.deselectedRowdata.map((item: { id: any }) => item.id)
        );

        const filteredArray = this.selectedRowdata.filter(
          (item: { id: unknown }) => !idsToRemove.has(item.id)
        );
        console.log('', filteredArray);
        this.selectedRowdata = filteredArray;
      }
    }
    console.log(this.selectedRowdata);
  }
  goToEditConsumer(dataItem: any): void {
    let data = dataItem;
    console.log('get by id grid values ', data);
    this.router.navigate(['/consumers/createConsumer'], {
      state: { consumerData: data },
    });
  }

  /* ActivationChange(event: any, dataItem: any): void {
    console.log(event);
    this.store.dispatch(
      getConsumerStstusId({ id: dataItem.id, status: event })
    );
  } */
  displaygrid = true;
  ActivationChange(event: any, dataItem: any): void {
    console.log(dataItem.status)
    let status = dataItem.status === 'Active' ? 'Inactive' : 'Active';
    const originalChecked = dataItem.status;
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-primary ms-2',
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        allowOutsideClick: false,
        // title: 'user Active/Inactive',
        text: `Are you sure you want to make this user ${status}?`,
        icon: 'warning',
        confirmButtonText: 'Yes, Continue',
        cancelButtonText: 'No, Cancel',
        showCancelButton: true,
        // timer: 20000, // close automatically after timeout
        //timerProgressBar: true,
      })
      .then((result) => {
        console.log(result, 'result');
        if (result.value) {
          this.store.dispatch(
            getConsumerStstusId({ id: dataItem.id, status: event })
          );
        }
        if (result.dismiss === Swal.DismissReason.cancel) {
          this.loaderService.setLoading(true);
          this.displaygrid = false;
          setTimeout(() => {
            this.displaygrid = true;
            this.loaderService.setLoading(false);
          });
        }
      });
  }
}
