import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConsumersRoutingModule } from './consumers-routing.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import {
  GridModule,
  PDFModule,
  ExcelModule,
} from '@progress/kendo-angular-grid';

import { TextBoxModule } from '@progress/kendo-angular-inputs';
import { ModalModule } from 'ngx-bootstrap/modal';
import { UiSwitchModule } from 'ngx-ui-switch';
import { SortableModule } from '@progress/kendo-angular-sortable';
import { UploadsModule } from '@progress/kendo-angular-upload';
import { SharedModule } from '../../shared/shared.module';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { LabelModule } from '@progress/kendo-angular-label';
import { AccordionModule } from 'ngx-bootstrap/accordion';

import { RpConsumersComponent } from './rp-consumers/rp-consumers.component';
import { RpCreateConsumersComponent } from './rp-create-consumers/rp-create-consumers.component';
import { ComponentsModule } from '../../shared/components/components.module';

@NgModule({
  declarations: [RpConsumersComponent, RpCreateConsumersComponent],
  imports: [
    CommonModule,
    ConsumersRoutingModule,
    NgSelectModule,
    BsDatepickerModule,
    GridModule,
    PDFModule,
    ExcelModule,
    // PDFExportModule,
    TextBoxModule,
    ModalModule,
    UiSwitchModule,
    SortableModule,
    DropDownsModule,
    UploadsModule,
    SharedModule,
    LabelModule,
    AccordionModule,
    ComponentsModule,
  ],
})
export class ConsumersModule {}
